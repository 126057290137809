h1 {
    font-size: 10rem;
    font-weight: 600;
    font-family: fantasy;
    margin: 0;
    padding: 0;
    text-align: center;
}

img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    h1 {
        font-size: 4rem;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}

/* Media Query for Tablets */
@media (min-width: 481px) and (max-width: 1024px) {
    h1 {
        font-size: 6rem;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}

/* Media Query for Laptops and above */
@media (min-width: 1025px) {
    h1 {
        font-size: 10rem;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
